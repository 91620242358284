export const environment = {
  production: false,
  backendUrl: 'https://test.srv.moondesk.design',
  clientVersion: '2.6.54',
  /**
   * ALLOWED DOMAIN: moondesk.design
   */
  adobeViewerApiKey: 'f6389532a286400ab43c7d06a15b981b',
  pdfjsWorkerUrl: '//cdn.jsdelivr.net/npm/pdfjs-dist@4.7.76/legacy/build/pdf.worker.mjs'
};
